<template>
  <div class="lesson" v-loading="pageLoading">
    <div class="right">
      <div class="idea-nav" v-if="category.length>1">
        <template v-for="(item, index) of category">
          <div
            class="idea-nav-item"
            :key="index"
            v-if="item.id != 3 && item.id != 4"
            @click="getList(index)"
            :class="{ active: classify == index }"
          >
            {{ item.name }}
          </div>
        </template>
      </div>
      <van-list
        v-loading="listLoading"
        v-if="list.length"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
      >
        <div class="list">
          <div
            class="card"
            v-for="item of list"
            :key="item.id"
            @click="liveplay(item)"
          >
            <div class="content">
              <van-image class="cover" fit="cover" :src="item.img_url">
                <template v-slot:error>
                  <van-image style="width: 100%; height: 100%" fit="cover" src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/tuoyu/img_default.png"></van-image>
                </template>
                <template v-slot:loading>
                  <van-image style="width: 100%; height: 100%" fit="cover" src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/tuoyu/img_default.png"></van-image>
                </template>
              </van-image>
              <div class="detail">
                <p class="title">
                  {{ item.name }}
                </p>
                <p class="text">主讲人：{{ item.host }}</p>
                <!-- <p class="text">
                  {{ item.description }}
                </p> -->
              </div>
              <div class="handle">
                <div class="btn">观看回放</div>
              </div>
            </div>
          </div>
        </div>
      </van-list>
      <van-empty
        class="emtpyMain"
        v-if="!list.length"
        description="暂无数据"
      />
    </div>
    <div class="left">
      <div class="classify" style="margin-bottom: 30px">
        <h2 class="classify-title">热门直播</h2>
        <div
          class="hot-item"
          v-for="(item, index) of topData"
          :key="index"
          @click="liveplay(item)"
        >
          <van-image class="cover" 
          :error-icon="$placeImg"
          :loading-icon="$placeImg"
          fit="cover" :src="item.cover" />

          <div class="detail">
            <p class="title">{{ item.name }}</p>
            <!-- <p class="subtitle">{{ item.description }}</p> -->
          </div>
        </div>
        <van-empty
          class="emptySmall"
          style="margin: 0 auto"
          v-if="!topData.length"
          description="暂无数据"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "lesson",
  data() {
    return {
      active: 0,
      list: [],
      tag: 1,
      classify: 0,
      certificate: [],
      topData: [],
      category: [],
      loading: false,
      pageLoading: true,
      finished: true,
      more: false,
      ideaNavData: [],
      ideaAllData: [],
      listLoading: false,
    };
  },
  async mounted() {
    window.addEventListener("scroll", this.onLoad);
    this.getData();
    let res = await this.$api({
      method: "get",
      url: "/api/livestream/hot",
    });
    if (res.code == 0) {
      this.topData = res.data;
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.onLoad);
  },
  computed: {
    plat() {
      return this.$store.state.plat_id;
    },
  },
  methods: {
    getTag(index) {
      this.tag = index;
    },
    async getList(index) {
      this.listLoading = true;
      this.classify = index;
      let res = await this.$api({
        method: "get",
        url: "/api/livestream/list",
        data: {
          category_id: this.category[this.classify].id,
          service_type: this.plat,
          type: 1,
        },
      });
      this.listLoading = false;
      if (res.code == 0) {
        this.list = res.data;
      } else {
        this.$toast(res.msg);
      }
    },
    async getData() {
      this.pageLoading = true;
      let res = await this.$api({
        method: "get",
        url: "/api/livestream/category",
        data: {
          flat_id: this.plat,
        },
      });
      this.pageLoading = false;
      if (res.code == 0) {
        // this.topData = res.data.top_list;
        this.category = res.data.live_type;
        await this.getList(this.$route.query.index || 0);
      } else {
        this.$toast(res.msg);
      }
    },
    
    liveplay(item) {
      console.log(item)
      let { name, video_url, id, video_id} = item;
      let type = 'ali';
      // if (ccid && ccid!='undefined') {
      //   type = 'cc';
      //   videoId = ccid;
      // }else if(url) {
      //   type = 'url';
      // }else if(!ali_media_id){
      //   console.log('none')
      //   this.$toast('当前视频未上传')
      //   return
      // }

      if(video_url || video_id){
        this.$store.commit('SET_VIDEO',null)

          this.$router.push(
            `./player?id=${id}&name=${name}&videoId=${video_id}&url=${encodeURI(video_url)}&from=直播&type=ali`
          );
      }else{
          this.$toast('没有视频');
      }
      // this.$router.push(
      //   `./player?name=${name}&url=${encodeURI(video_url)}&from=直播`
      // );
      // this.$api({
      //   method: "get",
      //   url: "/api/livestream/click",
      //   data: {
      //     id,
      //   },
      // });
    },
    getMore() {
      if (this.more) {
        return;
      }
      this.more = true;
      if (this.menu == 2) {
        axios
          .get(
            `/api/platform/pc/category?flat_id=${this.plat}&page=${this.page}`,
            {}
          )
          .then((res) => {
            this.more = false;
            console.log(res);
            if (res.code == 0) {
              let list = res.data.category[this.classify].goods;
              this.list = this.list.concat(list);
              this.loading = false;
              if (list.length < 10) {
                this.finished = true;
              } else {
                this.finished = false;
              }
            } else {
              this.$toast(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
            this.more = false;
          });
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate");
    console.log(to);
    next();
  },
};
</script>

<style lang="scss" scoped>
.lesson {
  min-height: calc(100vh - 230px);
  display: flex;
  align-items: flex-start;
  overflow: hidden;

  .van-sticky {
    left: auto;
    right: auto;
  }

  .left {
    margin-left: 40px;
    width: 300px;
    overflow: hidden;
    margin-top: 20px;
  }

  .classify {
    background: #fff;
    box-shadow: 0 5px 10px #33333308;
    border-radius: 12px;
    flex: 0 0 300px;
    display: flex;
    flex-wrap: wrap;
    padding: 10px 10px;

    .classify-title {
      font-size: 20px;
      padding: 0 20px 0;
      line-height: 1;
    }

    .classify-item {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #333;
      font-size: 18px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 14px 20px;

      &:hover {
        color: #a40001;
      }
    }

    .active {
      color: #a40001;
      font-weight: 600;
    }
  }

  .hot-title {
    font-size: 22px;
    font-weight: 500;
    padding: 0 20px 15px;
    width: 100%;
    display: block;
    margin-bottom: 10px;
  }

  .hot-item {
    width: 100%;
    display: flex;
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;

    &:hover {
      background: #f8f8f8;
    }

    .cover {
      flex: 0 0 84px;
      height: 84px;
      margin-right: 10px;
      ::v-deep img{
        border-radius: 6px;
      }
    }

    .detail {
      flex: auto;

      .title {
        font-size: 16px;
        font-weight: 500;
      }

      .subtitle {
        font-size: 12px;
        color: #666;
      }
    }
  }

  .right {
    flex: 0 0 860px;
    margin-top: 20px;
  }

  .card {
    background: #fff;
    box-shadow: 0 5px 10px #33333308;
    border-radius: 12px;
    padding: 20px 20px;
    margin-bottom: 20px;
    cursor: pointer;

    .title {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 10px;
    }

    .content {
      display: flex;
      width: 100%;

      .cover {
        flex: 0 0 280px;
        margin-right: 20px;
        width: 280px;
        height: 136px;
        border-radius: 10px;
        overflow: hidden;
      }

      .detail {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        flex: auto;

        .text {
          font-size: 14px;
          color: #666;
          overflow: hidden;
          display: -webkit-box;
          /*弹性伸缩盒子*/
          -webkit-box-orient: vertical;
          /*子元素垂直排列*/
          -webkit-line-clamp: 5;
          /*可以显示的行数，超出部分用...表示*/
          text-overflow: ellipsis;
          /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
        }

        .more {
          font-size: 14px;
          color: #175199;
          display: flex;
          align-items: center;
          margin-top: 10px;
          cursor: pointer;
        }
      }

      .handle {
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 120px;
      }

      .btn {
        cursor: pointer;
        border-radius: 6px;
        line-height: 40px;
        font-weight: 500;
        border-radius: 6px;
        color: #a40001;
        background: #f7f7f7;
        padding: 0 30px;
        font-size: 14px;

        &:hover {
          color: #fff;
          background: #a40001;
        }
      }
    }
  }

  .type {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 60px;
    margin-bottom: 20px;

    .type-btn {
      width: 140px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #a40001;
      background: #eee;
      border-radius: 4px;
      cursor: pointer;
      margin-right: 20px;
      transition: all 0.3s;

      &:hover {
        color: #fff;
        background: #a40001;
      }
    }

    .active {
      color: #fff;
      background: #a40001;
    }
  }

  .article {
    width: 960px;
    background: #fff;
    min-height: 100vh;
    margin: 20px auto;
    padding: 30px;

    .art-title {
      font-size: 32px;
      margin-bottom: 20px;
    }

    .art-user {
      display: flex;
      align-items: center;
    }

    .user-msg {
      font-size: 20px;
      color: #333;
      margin-left: 10px;
    }
  }

  .idea,
  .cert {
    .idea-nav,
    .idea-list,
    .cert-list {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .idea-nav-item {
      width: calc((100% - 200px) / 7);
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #a40001;
      background: #eee;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        color: #fff;
        background: #a40001;
      }
    }

    .idea-card {
      margin-top: 40px;
      width: calc((100% - 60px) / 3);
      // height: 300px;
      background: #fff;
      border-radius: 8px;
      cursor: pointer;
      padding: 20px;

      .card-title {
        font-weight: 500;
        margin-bottom: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        font-size: 26px;
      }

      .card-subtitle {
        overflow: hidden;
        font-size: 18px;
        color: #666;
        overflow: hidden;
        display: -webkit-box;
        /*弹性伸缩盒子*/
        -webkit-box-orient: vertical;
        /*子元素垂直排列*/
        -webkit-line-clamp: 5;
        /*可以显示的行数，超出部分用...表示*/
        text-overflow: ellipsis;
        /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
      }
    }

    .cert-card {
      width: calc((100% - 60px) / 3);
      background: #fff;
      border-radius: 8px;
      cursor: pointer;
      overflow: hidden;
      margin-bottom: 30px;
      margin-right: 20px;

      .card-detail {
        background: #fff;
        padding: 16px 16px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .card-cover {
        width: 100%;
        border-radius: 8px 8px 0 0;
        box-sizing: border-box;
      }

      .card-title {
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        font-size: 26px;
      }

      .card-btn {
        cursor: pointer;
        border-radius: 6px;
        line-height: 32px;
        font-weight: 500;
        border-radius: 6px;
        color: #a40001;
        background: #f7f7f7;
        padding: 0 20px;
        font-size: 16px;
        flex: 0 0 auto;

        &:hover {
          color: #fff;
          background: #a40001;
        }
      }
    }
  }
}
</style>

<style lang="scss">
</style>
